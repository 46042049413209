import { createRouter, createWebHashHistory } from 'vue-router'
import home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      title: '智能基础设施结构健康监测'
    }
  },
  {
    path: '/2d',
    name: '2d',
    meta: {
      title: '智能基础设施结构健康监测'
    },
    // component: One,
    component: () => import(/* webpackChunkName: "one" */ '../views/2d.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
