import request from '@/utils/fetch'
export default {
  /**
   * 公共POST请求
   * @param "请求接口地址" url
   * @param {请求参数} data
   * @returns {返回接口数据}
   */
  post(url, data) {
    return request({
      url: url,
      method: 'post',
      data: data
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded'
      // },
    })
  },
  /**
   * 公共GET请求
   * @param "请求接口地址" url
   * @param {请求参数} data
   * @returns {返回接口数据}
   */
  get(url, params) {
    return request({
      url: url,
      method: 'get',
      params: params
    })
  }
}
