import axios from 'axios'
import webConfig from '../config'
import qs from 'qs'
import { ElLoading } from 'element-plus'

const instance = axios.create({
  baseURL: webConfig.apiBaseUrl, // api的base_url
  timeout: process.env.NODE_ENV === 'production' ? 10000000 : 10000000, // 请求超时时间
  paramsSerializer: function(params) {
    return qs.stringify(params, { indices: false })
  }
  // transformRequest: data => qs.stringify(data) // 默认提交json格式
})
let loadingInstance
// request拦截器
instance.interceptors.request.use(
  (e) => {
    e.params = e.params || { loading: true }
    e.headers = e.headers || {}
    var loading = true
    if (e.params.loading === false) {
      loading = e.params.loading
    }
    if (loading) {
      loadingInstance = ElLoading.service({
        fullscreen: true,
        background: 'rgba(1, 21, 60, 0.7)'
      })
    }

    delete e.params.loading
    if (e.formType === true) {
      e.transformRequest = (data) => qs.stringify(data)
    }
    if (e.export === true) {
      e.responseType = 'arraybuffer'
    }
    return e
  },
  (error) => ({ status: 0, msg: error.message })
)
const ApiStatus = {
  ERROR: 500,
  SUCCESS: 200,
  TOKENINVALID: 401,
  PERMISSIONINVALID: 403
}
// respone拦截器
instance.interceptors.response.use(
  (response) => {
    loadingInstance.close()
    const resp = response.data
    if (resp && resp.status === 0) {
      return Promise.reject('error')
    }
    if (response.status === ApiStatus.SUCCESS) {
      return resp
    }
    if (
      response.status === ApiStatus.TOKENINVALID ||
      response.status === ApiStatus.PERMISSIONINVALID ||
      resp.status === ApiStatus.TOKENINVALID ||
      resp.status === ApiStatus.PERMISSIONINVALID
    ) {
      return Promise.reject('error')
    }
    return resp
  },
  (error) => {
    loadingInstance.close()
    if (
      error.message.indexOf(ApiStatus.TOKENINVALID) > 0 ||
      error.message.indexOf(ApiStatus.PERMISSIONINVALID) > 0
    ) {
      return Promise.reject('error')
    }

    return Promise.reject(error)
  }
)

export default instance
