import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api/index'
import common from './utils/common'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import './assets/css/common.css'
import 'animate.css'
import vueSeamlessScroll from '@zengxiaohui/vue3-seamless-scroll'
import locale from 'element-plus/lib/locale/lang/zh-cn'
const app = createApp(App)
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
app.use(vueSeamlessScroll)
app.use(store)
app.use(router)
app.use(ElementPlus, { locale })
app.config.globalProperties.$api = api // 自定义添加
app.config.globalProperties.$common = common // 自定义添加
app.mount('#app')
