<template>
  <div id="mapDiv" ref="cesium" />
  <div id="HOME" v-loading="loading">
    <div class="home-header pointer-events-auto">
      <el-row>
        <el-col :span="8" class="logo">
          <div v-if="config.logo" style="width: 180px;height: 34px;">
            <a v-if="config.logo_url" :href="config.logo_url" target="_blank">
              <el-image :src="$common.getImgUrl(config.logo)" style="width:100%;height:100%;" fit="contain" />
            </a>
            <el-image v-else :src="$common.getImgUrl(config.logo)" fit="contain" />
          </div>
        </el-col>
        <el-col :span="8" class="header-title">{{ config.web_title }}</el-col>
        <el-col :span="8" class="header-link">
          <a v-if="config.instruction_url" :href="config.instruction_url" target="_blank">使用说明及联系方式></a>
        </el-col>
      </el-row>
      <!-- <div class="header-date">
          2021-10-23 24:00:00 i
      </div>-->
    </div>
    <div class="home-main">
      <el-row>
        <el-col
          :span="5"
          :class="{'multi-card': config.music_url}"
          class="animate__animated animate__fadeInLeftBig animate__delay-1s "
        >
          <div class="home-card el-col-h-3">
            <img src="../assets/image/bg-1.png" alt class="card-bg">
            <div class="card-title no-border" />
            <div style="height: 98%; width: 98%" class="pointer-events-auto">
              <div
                id="line1"
                :style="{
                  width: '100%',
                  height: '100%',
                }"
              />
            </div>
          </div>
          <div class="home-card el-col-h-3">
            <img src="../assets/image/bg-1.png" alt class="card-bg">
            <div class="card-title no-border" />
            <div style="height: 98%; width: 98%" class="pointer-events-auto">
              <div
                id="line2"
                :style="{
                  width: '100%',
                  height: '100%',
                }"
              />
            </div>
          </div>
          <div class="home-card el-col-h-3">
            <img src="../assets/image/bg-1.png" alt class="card-bg">
            <div class="card-title no-border" />
            <div style="height: 98%; width: 98%" class="pointer-events-auto">
              <div
                id="line3"
                :style="{
                  width: '100%',
                  height: '100%',
                }"
              />
            </div>
          </div>
          <div v-if="config.music_url" class="home-card el-col-h-1 pointer-events-auto">
            <iframe
              :src="config.music_url"
              frameborder="0"
              width="101%"
              height="100%"
            />
            <!-- <img src="../assets/image/bg-6.png" alt class="card-bg">
            <div class="card-title no-border" />

          </div> -->
          </div></el-col>
        <el-col :span="14">
          <div style="height: 33.33%">
            <div
              v-if="config.notify_msg"
              class="
                home-card
                el-col-h-3
                animate__animated animate__fadeInDownBig animate__delay-1s
              "
              style="height: auto"
            >
              <div class="home-notic1" style="margin-top: 0px">
                <img src="../assets/image/bg-3.png" alt class="notic-bg">
                <div class="home-notic-content">
                  <i class="icon-notic" />
                  <div class="notic-title" style="font-weight: normal">
                    {{ config.notify_msg }}
                  </div>
                  <!-- <notic-date>2021-10-21</notic-date> -->
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="curDevice"
            class="
              home-card
              el-col-h-3
              notic-flex-bottom
              pointer-events-none
              animate__animated animate__fadeInUpBig animate__delay-1s
            "
            :style="!config.icp1&&!config.icp2?'height: 40%;':'height: 36%;'"
          >
            <div class="home-notic1" style="margin-bottom: -15px">
              <img src="../assets/image/bg-4.png" alt class="notic-bg">
              <div class="home-notic-content">
                <div class="notic-title">当前传感器 |</div>
                <div class="notic-text">
                  名称：{{ curDevice.title }}
                  经度：{{ curDevice.lon.toFixed(2) }}°
                  纬度：{{ curDevice.lat.toFixed(2) }}°
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              home-card
              el-col-h-3
              home-mid-state
              pointer-events-auto
              animate__animated animate__fadeInUpBig animate__delay-1s
            "
            :style="!config.icp1&&!config.icp2?'justify-content: flex-end;height: 26%;':'height: 30%;'"
          >
            <!-- eslint-disable-next-line vue/no-multiple-template-root-->
            <div class="home-state-bottom">
              <img src="../assets/image/bg-2.png" alt class="card-bg">
              <div class="card-title">
                <el-row>
                  <el-col :span="4">名称</el-col>
                  <el-col :span="14">状态</el-col>
                  <el-col :span="6" class="text-right">操作</el-col>
                </el-row>
              </div>
              <!-- <div style="height: 70%;">
              <el-scrollbar> -->
              <vue-seamless-scroll
                :data="deviceList"
                class="card-state-info"
                :class-option="classOption"
              >
                <el-row
                  v-for="(item, index) in deviceList"
                  :key="index"
                  class="state-item"
                >
                  <el-col :span="4">{{ item.title }}</el-col>
                  <el-col :span="14">{{ item.alarm_msg }}</el-col>
                  <el-col :span="6" class="text-right">
                    <el-button
                      type="primary"
                      size="small"
                      @click="popDetail(item)"
                    >详细</el-button>
                    <el-button
                      type="primary"
                      size="small"
                      @click="selectDevice(item)"
                    >地图</el-button>
                  </el-col>
                </el-row>
              </vue-seamless-scroll>
            <!-- </el-scrollbar>
            </div> -->
            </div>
            <div v-if="config.icp1||config.icp2" class="copy-right">
              <span class="icon gwab" style="padding-right: 10px;" />
              <span v-if="config.icp1" style="padding-right: 10px;">{{ config.icp1 }}</span>
              <span v-if="config.icp2">{{ config.icp2 }}</span>
            </div>
          </div>
        </el-col>

        <!-- 如果第一列是小card 动态 添加  multi-card 样式即可，如果 是正常三个大card，什么都 不加 -->
        <el-col
          :span="5"
          class="animate__animated animate__fadeInRightBig animate__delay-1s multi-card"
        >
          <div class="home-card el-col-h-1">
            <img src="../assets/image/bg-5.png" alt class="card-bg">
            <div class="card-info-content">
              <div class="info-warp">
                <span class="info-txt1">设备总数</span>
                <span class="info-tit1 text-color-1">{{
                  config.total_count
                }}</span>
              </div>
              <div class="info-warp">
                <span class="info-txt1">异常设备</span>

                <span class="info-tit1 text-color-2">{{
                  config.abnormal_count
                }}</span>
              </div>
            </div>
          </div>
          <!-- <div v-else class="home-card el-col-h-3">
            <img src="../assets/image/bg-1.png" alt class="card-bg">
            <div class="card-title">设备信息</div>
            <div class="card-info-content">
              <div class="info-warp">
                <div class="info-tit text-color-1">
                  {{ config.total_count }}
                </div>
                <div class="info-txt">设备总数</div>
              </div>
              <div class="info-divider-h" />
              <div class="info-warp">
                <div class="info-tit text-color-2">
                  {{ config.abnormal_count }}
                </div>
                <div class="info-txt">异常设备</div>
              </div>
            </div>
          </div> -->
          <div class="home-card el-col-h-3">
            <img src="../assets/image/bg-1.png" alt class="card-bg">
            <div class="card-title">天气预报</div>
            <div class="weather-warp">
              <el-scrollbar>
                <div class="weather-info">
                  <div class="weather-content weather-tit">
                    <div class="w-item">日期</div>
                    <div class="w-item-1">天气</div>
                    <div class="w-item">温度</div>
                    <div class="w-item">风力</div>
                  </div>
                  <div
                    v-for="(item, index) in weatherList"
                    :key="index"
                    class="weather-content"
                  >
                    <div class="w-item">{{ item.date }}</div>
                    <div class="w-item-1">
                      <el-image
                        class="icon-heavy-rain"
                        :src="
                          require('@/assets/image/weather/' +
                            item.dayweather +
                            '.png')
                        "
                        :fit="fill"
                      >
                        <template #error>
                          <el-image
                            class="icon-heavy-rain"
                            :src="require('@/assets/image/weather/默认.png')"
                            :fit="fill"
                          />
                        </template>
                      </el-image>
                      <div>{{ item.dayweather }}</div>
                    </div>
                    <div class="w-item">
                      {{ item.nighttemp }}~{{ item.daytemp }}℃
                    </div>
                    <div class="w-item">{{ item.daypower }}级</div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
          <div class="home-card el-col-h-3">
            <img src="../assets/image/bg-1.png" alt class="card-bg">
            <div class="card-title no-border" />
            <div
              v-if="curDevice && curDevice.img"
              class="card-content"
              :class="curDevice.url ? 'pointer-events-auto' : ''"
            >
              <el-image
                style="width: 90%; height: 100%"
                :src="$common.getImgUrl(curDevice.img)"
                :fit="contain"
                @click="
                  curDevice.url ? (drawerDevice = true) : (drawerDevice = false)
                "
              />
            </div>
            <div v-else class="card-content">
              <i class="icon-nodata" />
              <div class="nodata-txt">暂无传感器场景</div>
            </div>
          </div>
          <div
            class="home-card el-col-h-3 pointer-events-auto"
          >
            <img src="../assets/image/bg-1.png" alt class="card-bg">
            <div class="card-title no-border" />
            <div v-if="config.frame_url" class="card-content">
              <iframe
                :src="config.frame_url"
                frameborder="0"
                width="90%"
                height="100%"
              />
            </div>
            <div v-else class="card-content">
              <i class="icon-nodata" />
              <div class="nodata-txt">暂无信息</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- <div class="home-left"></div>
      <div class="home-main"></div>
    <div class="home-right"></div>-->
  </div>
  <el-dialog
    v-model="centerDialogVisible"
    width="700px"
    center
    :show-close="false"
  >
    <div class="dialog-content">
      <p>设备名称：{{ popDevice.title }}</p>
      <p>设备ID：{{ popDevice.did }}</p>
      <p>经纬度：（{{ popDevice.lon }}°，{{ popDevice.lat }}°）</p>
      <p>温度：{{ popDevice.temp }}℃</p>
      <p>电量：{{ popDevice.eqc }}%</p>
      <p>信号强度：{{ popDevice.csq }}</p>
      <p>倾角测量间隔(S)：{{ popDevice.freq }}</p>
      <p>X轴Y轴倾角值(°)：({{ popDevice.xang }},{{ popDevice.yang }})</p>
      <p>XYZ轴倾角变化值(°)：({{ popDevice.xang_delta }},{{ popDevice.yang_delta }},{{ popDevice.zang_delta }})</p>
      <p>XYZ轴倾角变化速率(°/hr)：({{ popDevice.xang_speed }},{{ popDevice.yang_speed }},{{ popDevice.zang_speed }})</p>
      <p>XYZ轴加速度(g)：({{ popDevice.xacc }},{{ popDevice.yacc }},{{ popDevice.zacc }})</p>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          class="btn-close"
          type="text"
          @click="centerDialogVisible = false"
        />
        <!-- <button class="btn-close"></button> -->
      </span>
    </template>
  </el-dialog>

  <!-- eslint-disable-next-line vue/no-multiple-template-root-->
  <el-drawer v-model="drawerDevice" direction="ltr" title="传感器详情" size="80%">
    <!-- <iframe :src="curDevice.url" frameborder="0" width="100%" height="100%" /> -->
    <iframe :src="curDevice.url" frameborder="0" width="100%" height="100%" />
  </el-drawer>
  <!-- eslint-disable-next-line vue/no-multiple-template-root -->
  <!-- <div ref="pop" style="display:none;position: absolute;left:0;top:0;background: #ffffff;padding: 20px;">
    <div v-if="curDevice">
      名称：{{ curDevice.title }}<br>
      纬度：{{ curDevice.lat }}<br>
      纬度：{{ curDevice.lon }}
    </div>
  </div> -->
  <!-- eslint-disable-next-line vue/no-multiple-template-root -->
  <div ref="pop" class="tdt-infowindow  tdt-zoom-animated" style="display:none;position: absolute;left:0;top:0; ">
    <div class="tdt-infowindow-content-wrapper">
      <div class="tdt-infowindow-content" style="width: 129px;">
        <div v-if="curDevice" style="margin:0px;">
          名称：{{ curDevice.title }}<br>
          纬度：{{ curDevice.lat }}<br>
          纬度：{{ curDevice.lon }}
        </div>
      </div>
    </div>
    <div class="tdt-infowindow-tip-container">
      <div class="tdt-infowindow-tip" />
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { ElMessage as msg } from 'element-plus'
var moment = require('moment')

let myChart1
let myChart2
let myChart3
let dingshi
const Cesium = window.Cesium
export default {
  components: {},
  data() {
    return {
      classOption: {
        step: 0.3,
        hoverStop: true,
        limitMoveNum: 4
      },
      viewer: {},
      centerDialogVisible: false,
      type: 1,
      deviceList: [],
      curDevice: null,
      popDevice: null,
      loading: false,
      markerList: [],
      drawerDevice: false,
      config: {
        notify_msg: null,
        total_count: 0,
        abnormal_count: 0,
        refresh_enable: 0,
        refresh_time: 0,
        frame_url: null,
        logo: null,
        logo_url: null,
        instruction_url: null, // 使用说明链接
        icp1: null, // 公安备案
        icp2: null, // 域名备案
        music_url: null// 音乐链接
      },
      weatherList: []
    }
  },
  created() {
    this.$nextTick(() => {
      this.initTdtMap()
      this.getConfig()
    })
  },
  methods: {
    getWeather() {
      this.$api
        .get('https://restapi.amap.com/v3/weather/weatherInfo', {
          city: this.config.infocode,
          key: 'd967571f99236757babbacec117c4829',
          extensions: 'all'
        })
        .then((res) => {
          this.loading = false
          if (res.status !== '1') {
            return msg.error(res.info)
          }
          res.forecasts[0].casts.forEach((e) => {
            e.date = moment(e.date).format('MM月DD日')
          })
          this.weatherList = res.forecasts[0].casts
        })
    },
    getConfig(isUpdate) {
      var l = true
      if (isUpdate) l = false
      var _this = this
      this.$api.get('/screen/notify', { loading: l }).then((res) => {
        if (res.code !== 200) {
          return msg.error(res.msg)
        }
        const refresh_enable = this.config.refresh_enable

        this.config = res.data

        document.title = this.config.web_title

        if (!isUpdate) {
          this.getDevice()
          this.getWeather()
          var time = 20000
          if (this.config.refresh_time && this.config.refresh_time > 0) {
            time = this.config.refresh_time
          }
          setInterval(() => {
            this.getConfig(true)
            _this.getDevice(true)
          }, Number(time))
        } else {
          if (refresh_enable !== res.data.refresh_enable && res.data.refresh_enable === 1) {
            this.selectDevice(this.deviceList[0])
          }
        }
      })
    },
    popDetail(item) {
      this.$api.get('/screen/sensor/detail', {
        did: item.did
      }).then((res) => {
        if (res.code !== 200) {
          return msg.error(res.msg)
        }
        this.popDevice = res.data
        this.centerDialogVisible = true
      })
    },
    getDevice(isUpdate) {
      var l = true
      if (isUpdate) l = false
      this.$api
        .get('/screen/avalible/sensors', { loading: l })
        .then((res) => {
          if (res.code !== 200) {
            return msg.error(res.msg)
          }
          var T = window.T
          if (!isUpdate) {
            this.deviceList = res.data.sensors
            this.markerList = []
            if (this.deviceList.length > 0) {
              this.selectDevice(this.deviceList[0], true)
            }
            this.marker()
          } else {
            res.data.sensors.forEach((e) => {
              var item = this.deviceList.find((d) => d.did === e.did)
              if (item) {
                item.alarm_msg = e.alarm_msg
                item.title = e.title
                item.img = e.img
              } else {
                this.deviceList.push(e)
                var marker = new T.Marker(new T.LngLat(e.lon, e.lat))
                marker.addEventListener('click', this.markerClick)
                marker.device = e
                marker.marker = marker
                this.map.addOverLay(marker)
                this.markerList[e.did] = marker
              }
            })
          }
        })
    },
    markerClick(e) {
      this.selectDevice(e.target.device)
    },
    selectDevice(item, noZoom) {
      if (dingshi) clearInterval(dingshi)
      var _this = this
      if (!noZoom) {
        this.viewer.camera.flyTo({ // 相机飞往该点
          destination: Cesium.Cartesian3.fromDegrees(item.lon, item.lat, 500), // 摄像机的最终位置
          duration: 2
        })
      }
      this.curDevice = item
      this.viewer.entities.removeAll()
      this.marker()
      this.loadLine(item.did)
      if (this.config.refresh_enable === 1) {
        dingshi = setInterval(() => {
          var curindex = _this.deviceList.findIndex((e) => e.did === item.did)
          if (_this.deviceList[curindex + 1]) {
            _this.selectDevice(_this.deviceList[curindex + 1])
          } else {
            _this.selectDevice(_this.deviceList[0])
          }
          curindex++
        }, 10000)
      }
    },
    initTdtMap() {
      var token = 'db4e885f23d29f1c6f4f8d858483382d'
      // 服务域名
      var tdtUrl = 'https://t{s}.tianditu.gov.cn/'
      // 服务负载子域
      var subdomains = ['0', '1', '2', '3', '4', '5', '6', '7']

      // cesium 初始化
      this.viewer = new Cesium.Map('mapDiv', {
        shouldAnimate: true,
        selectionIndicator: true,
        infoBox: false
      })

      // 抗锯齿
      this.viewer.scene.postProcessStages.fxaa.enabled = false
      // 水雾特效
      this.viewer.scene.globe.showGroundAtmosphere = true
      // 设置最大俯仰角，[-90,0]区间内，默认为-30，单位弧度
      this.viewer.scene.screenSpaceCameraController.constrainedPitch = Cesium.Math.toRadians(-20)
      // 取消默认的双击事件
      this.viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK)

      // 叠加影像服务
      var imgMap = new Cesium.UrlTemplateImageryProvider({
        url: tdtUrl + 'DataServer?T=img_w&x={x}&y={y}&l={z}&tk=' + token,
        subdomains: subdomains,
        tilingScheme: new Cesium.WebMercatorTilingScheme(),
        maximumLevel: 18
      })
      this.viewer.imageryLayers.addImageryProvider(imgMap)

      // 叠加国界服务
      var iboMap = new Cesium.UrlTemplateImageryProvider({
        url: tdtUrl + 'DataServer?T=ibo_w&x={x}&y={y}&l={z}&tk=' + token,
        subdomains: subdomains,
        tilingScheme: new Cesium.WebMercatorTilingScheme(),
        maximumLevel: 10
      })
      this.viewer.imageryLayers.addImageryProvider(iboMap)

      // 叠加地形服务
      var terrainUrls = []
      for (var i = 0; i < subdomains.length; i++) {
        var url = tdtUrl.replace('{s}', subdomains[i]) + 'DEM90M/DataServer?T=elv_c&tk=' + token
        terrainUrls.push(url)
      }

      var provider = new Cesium.GeoTerrainProvider({
        urls: terrainUrls
      })

      this.viewer.terrainProvider = provider

      // 将三维球定位到中国
      this.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(103.84, 31.15, 17850000),
        orientation: {
          heading: Cesium.Math.toRadians(348.4202942851978),
          pitch: Cesium.Math.toRadians(-89.74026687972041),
          roll: Cesium.Math.toRadians(0)
        },
        complete: function callback() {
        // 定位完成之后的回调函数
        }
      })

      // 叠加三维地名服务
      var wtfs = new Cesium.GeoWTFS({
        viewer: this.viewer,
        // 三维地名服务，使用wtfs服务
        subdomains: subdomains,
        metadata: {
          boundBox: {
            minX: -180,
            minY: -90,
            maxX: 180,
            maxY: 90
          },
          minLevel: 1,
          maxLevel: 20
        },
        aotuCollide: true, // 是否开启避让
        collisionPadding: [5, 10, 8, 5], // 开启避让时，标注碰撞增加内边距，上、右、下、左
        serverFirstStyle: true, // 服务端样式优先
        labelGraphics: {
          font: '28px sans-serif',
          fontSize: 28,
          fillColor: Cesium.Color.WHITE,
          scale: 0.5,
          outlineColor: Cesium.Color.BLACK,
          outlineWidth: 5,
          style: Cesium.LabelStyle.FILL_AND_OUTLINE,
          showBackground: false,
          backgroundColor: Cesium.Color.RED,
          backgroundPadding: new Cesium.Cartesian2(10, 10),
          horizontalOrigin: Cesium.HorizontalOrigin.MIDDLE,
          verticalOrigin: Cesium.VerticalOrigin.TOP,
          eyeOffset: Cesium.Cartesian3.ZERO,
          pixelOffset: new Cesium.Cartesian2(0, 8)
        },
        billboardGraphics: {
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          eyeOffset: Cesium.Cartesian3.ZERO,
          pixelOffset: Cesium.Cartesian2.ZERO,
          alignedAxis: Cesium.Cartesian3.ZERO,
          color: Cesium.Color.WHITE,
          rotation: 0,
          scale: 1,
          width: 18,
          height: 18
        }
      })

      // 三维地名服务，使用wtfs服务
      wtfs.getTileUrl = function() {
        return tdtUrl + 'mapservice/GetTiles?lxys={z},{x},{y}&tk=' + token
      }

      // 三维图标服务
      wtfs.getIcoUrl = function() {
        return tdtUrl + 'mapservice/GetIcon?id={id}&tk=' + token
      }

      wtfs.initTDT([{ 'x': 6, 'y': 1, 'level': 2, 'boundBox': { 'minX': 90, 'minY': 0, 'maxX': 135, 'maxY': 45 }}, { 'x': 7, 'y': 1, 'level': 2, 'boundBox': { 'minX': 135, 'minY': 0, 'maxX': 180, 'maxY': 45 }}, { 'x': 6, 'y': 0, 'level': 2, 'boundBox': { 'minX': 90, 'minY': 45, 'maxX': 135, 'maxY': 90 }}, { 'x': 7, 'y': 0, 'level': 2, 'boundBox': { 'minX': 135, 'minY': 45, 'maxX': 180, 'maxY': 90 }}, { 'x': 5, 'y': 1, 'level': 2, 'boundBox': { 'minX': 45, 'minY': 0, 'maxX': 90, 'maxY': 45 }}, { 'x': 4, 'y': 1, 'level': 2, 'boundBox': { 'minX': 0, 'minY': 0, 'maxX': 45, 'maxY': 45 }}, { 'x': 5, 'y': 0, 'level': 2, 'boundBox': { 'minX': 45, 'minY': 45, 'maxX': 90, 'maxY': 90 }}, { 'x': 4, 'y': 0, 'level': 2, 'boundBox': { 'minX': 0, 'minY': 45, 'maxX': 45, 'maxY': 90 }}, { 'x': 6, 'y': 2, 'level': 2, 'boundBox': { 'minX': 90, 'minY': -45, 'maxX': 135, 'maxY': 0 }}, { 'x': 6, 'y': 3, 'level': 2, 'boundBox': { 'minX': 90, 'minY': -90, 'maxX': 135, 'maxY': -45 }}, { 'x': 7, 'y': 2, 'level': 2, 'boundBox': { 'minX': 135, 'minY': -45, 'maxX': 180, 'maxY': 0 }}, { 'x': 5, 'y': 2, 'level': 2, 'boundBox': { 'minX': 45, 'minY': -45, 'maxX': 90, 'maxY': 0 }}, { 'x': 4, 'y': 2, 'level': 2, 'boundBox': { 'minX': 0, 'minY': -45, 'maxX': 45, 'maxY': 0 }}, { 'x': 3, 'y': 1, 'level': 2, 'boundBox': { 'minX': -45, 'minY': 0, 'maxX': 0, 'maxY': 45 }}, { 'x': 3, 'y': 0, 'level': 2, 'boundBox': { 'minX': -45, 'minY': 45, 'maxX': 0, 'maxY': 90 }}, { 'x': 2, 'y': 0, 'level': 2, 'boundBox': { 'minX': -90, 'minY': 45, 'maxX': -45, 'maxY': 90 }}, { 'x': 0, 'y': 1, 'level': 2, 'boundBox': { 'minX': -180, 'minY': 0, 'maxX': -135, 'maxY': 45 }}, { 'x': 1, 'y': 0, 'level': 2, 'boundBox': { 'minX': -135, 'minY': 45, 'maxX': -90, 'maxY': 90 }}, { 'x': 0, 'y': 0, 'level': 2, 'boundBox': { 'minX': -180, 'minY': 45, 'maxX': -135, 'maxY': 90 }}])
      // 去掉entity的点击事件 start
      this.viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK)
      this.viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK)
      // 去掉entity的点击事件 end
      //   this.viewer.screenSpaceEventHandler.setInputAction(
      //     (movement) => {
      //       const entity = this.viewer.scene.pick(movement.position)
      //       console.log(entity)
      //       if (Cesium.defined(entity)) {
      //         this.addInfoWindow({
      //           // eslint-disable-next-line no-undef
      //           position: movement.position,
      //           offset: { x: 0, y: 36 },
      //           device: entity.id.device
      //         })
      //       }
      //     },
      //     Cesium.ScreenSpaceEventType.LEFT_CLICK
      //   )
      // 点击地图事件
      const handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas)
      handler.setInputAction((click) => {
        const entity = this.viewer.scene.pick(click.position)
        if (Cesium.defined(entity)) {
          this.selectDevice(entity.id.device)
        }
        // // 屏幕坐标转世界坐标——关键点
        // const ellipsoid = this.viewer.scene.globe.ellipsoid
        // const cartesian = this.viewer.camera.pickEllipsoid(click.position, ellipsoid)
        // if (cartesian) { // 判断点击的是否是地球
        // // 将笛卡尔坐标转换为地理坐标
        //   const cartographic = Cesium.Cartographic.fromCartesian(cartesian)
        //   // 将弧度转为度的十进制度表示
        //   const lon = Cesium.Math.toDegrees(cartographic.longitude)
        //   const lat = Cesium.Math.toDegrees(cartographic.latitude)
        //   const click_point = { longitude: lon, latitude: lat }
        //   const entity = this.viewer.scene.pick(click.position)
        //   if (Cesium.defined(entity)) {
        //     this.addInfoWindow({
        //       device: entity.id.device,
        //       point: click_point
        //     })
        //   }
        // }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
    },
    marker() {
      this.deviceList.forEach((e) => {
        this.viewer.entities.add({
          device: e,
          position: Cesium.Cartesian3.fromDegrees(e.lon, e.lat, 2.61),
          billboard: {
            id: e.did,
            // 图像地址，URI或Canvas的属性
            image: e.did === this.curDevice.did ? 'https://api.smartbreakwaters.com/logo/location_check.png' : 'http://api.tianditu.gov.cn/v4.0/image/marker-icon.png',
            // 设置颜色和透明度
            color: Cesium.Color.WHITE.withAlpha(1),
            // 高度（以像素为单位）
            height: 41,
            // 宽度（以像素为单位）
            width: 25,
            // 大小是否以米为单位
            sizeInMeters: false,
            // 相对于坐标的垂直位置
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            // 相对于坐标的水平位置
            horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
            // 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
            pixelOffset: new Cesium.Cartesian2(10, 0),
            // 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
            scale: 1.0,
            // 是否显示
            show: true
          }
        })
      })
    },
    loadLine(did) {
      myChart1 = echarts.init(document.getElementById('line1'))
      myChart1.clear()
      myChart1.resize()
      myChart2 = echarts.init(document.getElementById('line2'))
      myChart2.clear()
      myChart2.resize()
      myChart3 = echarts.init(document.getElementById('line3'))
      myChart3.clear()
      myChart3.resize()
      var _this = this

      _this.$api
        .get('/screen/data/history', {
          did: did,
          loading: false
        })
        .then((res) => {
          if (res.code !== 200) {
            return msg.error(res.msg)
          }

          myChart1.setOption({
            title: {
              text: '构件倾角(°)',
              textStyle: {
                color: '#0bd3f4',
                fontSize: 16
              },
              top: 'top'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              },
              formatter: function(datas) {
                var res = datas[0].name + '<br/>'
                for (var i = 0, length = datas.length; i < length; i++) {
                  res +=
                    datas[i].marker +
                    datas[i].seriesName +
                    '：' +
                    datas[i].value +
                    ' °<br/>'
                }
                return res
              }
            },

            grid: {
              top: '15%',
              left: '2%',
              right: '4%',
              bottom: '20%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: res.data.chartA.category,
                axisLabel: {
                  color: '#ABDBFF'
                },
                splitNumber: 10
              }
            ],

            yAxis: {
              type: 'value',
              min: -90,
              max: 90,
              axisLabel: {
                formatter: '{value}',
                color: '#ABDBFF'
              },
              splitLine: {
                lineStyle: {
                  color: '#0f649c'
                }
              }
            },
            legend: {
              data: [
                {
                  name: '顺堤向',
                  itemStyle: {
                    color: '#5b9ad4'
                  }
                },
                {
                  name: '横堤向',
                  itemStyle: {
                    color: '#ff6700'
                  }
                }
              ],
              icon: 'circle',
              itemHeight: 8,
              textStyle: {
                color: '#a9dcff'
              },
              left: 'right'
            },
            series: [
              {
                name: '顺堤向',
                type: 'line',
                stack: '',
                smooth: true,
                symbol: 'none',
                itemStyle: {
                  normal: {
                    color: '#5b9ad4'
                  }
                },
                data: res.data.chartA.line1
              },
              {
                name: '横堤向',
                type: 'line',
                stack: '',
                smooth: true,
                symbol: 'none',
                itemStyle: {
                  normal: {
                    color: '#ff6700'
                  }
                },
                data: res.data.chartA.line2
              }
            ]
          })

          myChart2.setOption({
            title: {
              text: '倾角变化率(°/h)',
              textStyle: {
                color: '#0bd3f4',
                fontSize: 16
              },
              top: 'top'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              },
              formatter: function(datas) {
                var res = datas[0].name + '<br/>'
                for (var i = 0, length = datas.length; i < length; i++) {
                  res +=
                    datas[i].marker +
                    datas[i].seriesName +
                    '：' +
                    datas[i].value +
                    '(°/h)<br/>'
                }
                return res
              }
            },

            grid: {
              top: '15%',
              left: '2%',
              right: '4%',
              bottom: '20%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: res.data.chartB.category,
                axisLabel: {
                  color: '#ABDBFF'
                },
                splitNumber: 10
              }
            ],

            yAxis: {
              type: 'value',
              min: -90,
              max: 90,
              axisLabel: {
                formatter: '{value}',
                color: '#ABDBFF'
              },
              splitLine: {
                lineStyle: {
                  color: '#0f649c'
                }
              }
            },
            legend: {
              data: [
                {
                  name: '顺堤向',
                  itemStyle: {
                    color: '#5b9ad4'
                  }
                },
                {
                  name: '横堤向',
                  itemStyle: {
                    color: '#ff6700'
                  }
                }
              ],
              icon: 'circle',
              itemHeight: 8,
              textStyle: {
                color: '#a9dcff'
              },
              left: 'right'
            },
            series: [
              {
                name: '顺堤向',
                type: 'line',
                stack: '',
                smooth: true,
                symbol: 'none',
                itemStyle: {
                  normal: {
                    color: '#5b9ad4'
                  }
                },
                data: res.data.chartB.line1
              },
              {
                name: '横堤向',
                type: 'line',
                stack: '',
                smooth: true,
                symbol: 'none',
                itemStyle: {
                  normal: {
                    color: '#ff6700'
                  }
                },
                data: res.data.chartB.line2
              }
            ]
          })

          myChart3.setOption({
            title: {
              text: '传感器状态',
              textStyle: {
                color: '#0bd3f4',
                fontSize: 16
              },
              top: 'top'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              },
              formatter: function(datas) {
                return (
                  '<span>' +
                  datas[0].name +
                  '</span><br/>' +
                  '<span>传感器温度(℃)：' +
                  datas[0].value +
                  '</span><br/>' +
                  '<span>剩余电量(%)：' +
                  datas[1].value +
                  '</span>'
                )
              }
            },

            grid: {
              top: '15%',
              left: '2%',
              right: '4%',
              bottom: '20%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: res.data.chartC.category,
                axisLabel: {
                  color: '#ABDBFF'
                }
              }
            ],

            yAxis: {
              type: 'value',
              min: -20,
              max: 100,
              axisLabel: {
                formatter: '{value}',
                color: '#ABDBFF'
              },
              splitLine: {
                lineStyle: {
                  color: '#0f649c'
                }
              }
            },
            legend: {
              data: [
                {
                  name: '温度(℃)',
                  itemStyle: {
                    color: '#5b9ad4'
                  }
                },
                {
                  name: '电量(%)',
                  itemStyle: {
                    color: '#ff6700'
                  }
                }
              ],
              icon: 'circle',
              itemHeight: 8,
              textStyle: {
                color: '#a9dcff'
              },
              left: 'right'
            },
            series: [
              {
                name: '温度(℃)',
                type: 'line',
                stack: '',
                smooth: true,
                symbol: 'none',
                itemStyle: {
                  normal: {
                    color: '#5b9ad4'
                  }
                },
                data: res.data.chartC.line1
              },
              {
                name: '电量(%)',
                type: 'line',
                stack: '',
                smooth: true,
                symbol: 'none',
                itemStyle: {
                  normal: {
                    color: '#ff6700'
                  }
                },
                data: res.data.chartC.line2
              }
            ]
          })
        })
      setTimeout(function() {
        window.onresize = function() {
          myChart1.resize()
          myChart2.resize()
          myChart3.resize()
        }
      }, 200)
    }
  }
}
</script>
